(function() {
    'use strict';

    angular.module('ead', [
        'ead.core',
        'ead.home',
        'ead.uc',
        'ead.data',
        'ead.video'
    ]).controller('eadCtrl', Controller);

    Controller.$inject = ['config', '$scope'];
    function Controller(config, $scope) {

        // Rotas Angular
        $scope.route = function (url, id) {
            var link = config.baseUrl + '/#' + url;

            if(id) link + '/' + id;

            return link;
        };

        // URL Laravel
        $scope.url = function (url) {
            return config.baseUrl + url;
        };

        // ASSET Laravel
        $scope.asset = function (url) {
            return config.baseUrl + '/images/' + url;
        };
    }
})();
