(function(){
    'use strict';

    angular
        .module('ead.uc')
        .controller('UcSingleCtrl', UcSingle);

    UcSingle.$inject = ['$scope', '$rootScope', '$location', 'config', '$routeParams', 'ucService'];

    function UcSingle($scope, $rootScope, $location, config, $routeParams, ucService) {

		var ucId = $routeParams.uc_id;
		var vm = this;
		// vm.uc = normalizeData(ucService.getUcModules(ucId));
		vm.uc = ucService.getUcModules(ucId);
		vm.getClassForPretest = getClassForPretest;
		vm.isPracticeBlocked = isPracticeBlocked;
		vm.getPracticeClassWrap  = getPracticeClassWrap;
		vm.isPracticeButtonBlocked = isPracticeButtonBlocked;
		vm.getClassIconWrap = getClassIconWrap;
		vm.getFinalTestClassWrap = getFinalTestClassWrap;

		vm.getClassWrapperByTypeItem = getClassWrapperByTypeItem;
		vm.getClassBorderByItem = getClassBorderByItem;
		vm.getTitleByType = getTitleByType;
		vm.isTypeItemCourse = isTypeItemCourse;
		vm.getClassIconColorByItem = getClassIconColorByItem;
		vm.getIconStateByItem = getIconStateByItem;
    }

    function normalizeData(uc) {

    	var data = [];

    	if (uc.pretest) {
    		uc.pretest.typeItem = 'pretest';
    		data.push(uc.pretest);
    	}

    	if (uc.courses) {
    		for (var k in uc.courses) {
	    		var course = uc.courses[k];
	    		course.typeItem = 'course';
	    		data.push(course);
    		}
    	}

    	if (uc.pretest) {
    		uc.practice.typeItem = 'practice';
    		data.push(uc.practice);
    	}

    	if (uc.finaltest) {
    		uc.finaltest.typeItem = 'finaltest';
    		data.push(uc.finaltest);
    	}

    	if (uc.certificate) {
    		uc.certificate.typeItem = 'certificate';
    		data.push(uc.certificate);
    	}

    	uc.data = data;

    	return uc;
    }

    function getClassWrapperByTypeItem(typeItem) {
    	return {
    		'pretest' : 'pre-test-holder',
    		'course' : 'item-course-holder',
    		'practice' : 'item-practice-holder',
    		'finaltest' : 'final-evaluation-holder',
    		'certificate' : 'certificate-holder',
    	}[typeItem];
    }

    function getTitleByType(typeItem) {
    	return {
    		'pretest' : 'Pré-teste',
    		'course' : 'item-course-holder',
    		'practice' : 'Aula Prática',
    		'finaltest' : 'Avaliação Finals',
    		'certificate' : 'Emita seu certificado',
    	}[typeItem];
    }

    function getClassBorderByItem (key, data) {

    	for (var k = key; k > 0; k--) {
    		if (data[k].completed) {
    			return 'panel-bordered-success';
    		}

    		else if (data[k].required && !data[k].completed) {
				return 'panel-bordered-danger';
    		}
    	}

    	return 'panel-bordered-success';
    }

    function getClassIconColorByItem (key, data) {
    	for (var k = key; k > 0; k--) {
    		if (data[k].completed) {
    			return 'success-icon';
    		}

    		else if (data[k].required && !data[k].completed) {
				return 'icon-holder-danger text-danger';
    		}
    	}

    	return (data[key].completed)? 'success-icon' : 'icon-holder-danger text-danger';

    	return 'icon-holder-danger text-danger';
    }

    function getIconStateByItem (key, data) {
    	var result = (data[key].completed)? 'ti-check' : 'ti-more-alt';

    	for (var k = 0; k >= key; k++) {
    		if (data[k].required && !data[k].completed ) {
    			result = 'ti-lock';
    			continue;
    		}
    	}

    	return result;
    }

    function isTypeItemCourse (typeItem) {
    	return typeItem === 'course';
    }

    function getClassForPretest(complete) {
		return (complete)? 'panel-flex': 'panel-lg-flex';
    }

    function hasCoursePendence(courses) {
    	return !(courses[courses.length-1].completed);
    }

    function isPracticeBlocked(courses) {
    	return hasCoursePendence(courses);
    }

    function isPracticeButtonBlocked(uc) {
    	return (isPracticeBlocked(uc.courses) || uc.practice.completed);
    }

    function getPracticeClassWrap(courses) {
    	return isPracticeBlocked(courses)? 'panel-bordered-danger' : 'panel-bordered-success';
    }

    function getClassIconWrap(uc) {

    	if (isPracticeBlocked(uc.courses)) {
    	  return 'ti-lock';
    	}

    	if (uc.practice.completed) {
    		return 'ti-check';	
    	}

    	return 'ti-more-alt';
    }

    function hasPractice(uc) {
    	return uc.has_practice;
    }

    function hasPracticePendence(uc) {
    	return (uc.practice.completed || !uc.practice.required)? false : true;
    }

    function getFinalTestClassWrap (uc) {

    	if (hasPractice(uc)) {
    		return (hasPracticePendence(uc))? 'panel-bordered-danger' : 'panel-bordered-success';
    	}

    	if (uc.courses.length > 0) {
    		return (hasCoursePendence(uc))? 'panel-bordered-danger' : 'panel-bordered-success';
    	}

    	return (uc.pretest.completed || !uc.pretest.required)? 'panel-bordered-success' : '';
    }

})();

