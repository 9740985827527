(function () {
    'use strict';

    angular.module('ead')
        .config(routeConfig);

    function routeConfig($routeProvider, $locationProvider) {
        $routeProvider
            .when('/', {
                templateUrl: window.location.pathname + '/../angular-ead/pages/home/index.html',
                controller: 'HomeCtrl',
                controllerAs: 'vm'
            })
        //.otherwise ({ redirectTo: '/' });
        //$locationProvider.html5Mode(true);
    }
})();