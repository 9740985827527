(function() {
    'use strict';

    angular
        .module('ead')
        .directive('preTest', PreTest);

    function PreTest() {
        return {
            restrict: 'E',
            templateUrl: window.location.pathname + '/../angular-ead/pages/home/modal/preTest.html',
            controller: Controller,
            controllerAs: 'vmPreTest'
        };
    }

    Controller.$inject = ['$scope', '$element', 'config'];
    function Controller($scope, $element, config) {

    }
})();