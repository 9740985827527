(function(){
    'use strict';

    angular
        .module('ead.data')
        .controller('dataService', DataService);

    DataService.$inject = ['$http', 'config'];
    function DataService($http, config) {
        var baseUrl = config.baseUrl,
            start_time = new Date(),
            request_time = 0;
    }

})();