
(function() {
    'use strict';

    angular
        .module('ead')
        .directive('cardCourse', Card);

    function Card() {
        return {
            restrict: 'E',
            templateUrl: window.location.pathname + '/../angular-ead/pages/home/card/cardCourse.html',
            controller: Controller,
            controllerAs: 'vmCard'
        };
    }

    Controller.$inject = ['$scope', '$element', 'config'];
    function Controller($scope, $element, config) {
       
    }
})();