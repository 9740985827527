$(document).ready(function() {
    $('#agree_terms').on('change', function() {
        // Setup Vars based in checkbox change
        var user_id = $(this).data('user');
        var checked = $(this).prop('checked');

        // Setup vars based in localStorage
        var users_allowed = localStorage.getItem('users_allowed');
        var agree_term = localStorage.getItem('agree_term');
        var users_allowed = localStorage.getItem('users_allowed');
        var agree_term = localStorage.getItem('agree_term');

        // Setup users_arr based in localStorage
        var users_arr = formatUsersArr(users_allowed);

        // Check if term already marked true and check wich user marked term
        if( checkIfHasMarkedTerm(agree_term, users_allowed, checked) )
        {
            mountLocalStorage(users_arr, user_id);

            hideModal();
        }
    });
});

function formatUsersArr(users_allowed) {
    return (users_allowed != '' && users_allowed != null) ? JSON.parse(users_allowed) : [];
}

function checkIfHasMarkedTerm(term, users, checked) {
    return (term != 'true' && users == '' && checked ) 
        || (term != 'true' && users != '' && checked) 
        || (term == 'true' && users != '' && checked);
}

function mountLocalStorage(users_arr, user_id) {
    localStorage.setItem('agree_term', 'true');

    if(users_arr.indexOf(parseInt(user_id)) == -1)
    {
        var newUsersArr = mountArr(users_arr, user_id);

        localStorage.setItem('users_allowed', JSON.stringify(newUsersArr));
    }
}

function mountArr(users, user_id) {
    var newUsersArr = users;

    newUsersArr.push(user_id);

    return newUsersArr;
}

function hideModal() {
    $(".main-modal-container-career-holder").removeClass('active');

    setTimeout(function(){
        $(".main-modal-container-career-holder").removeClass('animation');
    }, 100);
}