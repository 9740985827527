(function() {
    'use strict';

    angular
        .module('ead')
        .directive('drag', Drag);

    function Drag() {
        return {
            restrict: 'E',
            templateUrl: window.location.pathname + '/../angular-ead/pages/home/modal/drag.html',
            controller: Controller,
            controllerAs: 'vmDrag'
        };
    }

    Controller.$inject = ['$scope', '$element', 'config'];
    function Controller($scope, $element, config) {

    }
})();