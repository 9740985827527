(function(){
    'use strict';

    angular
        .module('ead.home')
        .controller('HomeCtrl', Home);

    Home.$inject = ['$scope', '$rootScope', '$location', 'config'];
    function Home($scope, $rootScope, $location, config) {


    }

})();