$('#directionship-career-select').on('change', function() {
    var url = window.location.origin,
        id = $(this).val();
    
    if(id != '')
    {
        window.location.href = url + '/trilhas-de-carreira/diretoria/' + id;
    } else {
        window.location.href = url + '/trilhas-de-carreira';
    }
});

$('#area-career-select').on('change', function() {
    var url = window.location.origin,
        id = $(this).val();

    if(id != '')
    {
        window.location.href = url + '/trilhas-de-carreira/area/' + id;
    } else {
        window.location.href = url + '/trilhas-de-carreira';
    }
});