(function(){
    'use strict';

    angular
        .module('ead.data')
        .controller('DataCtrl', Data);

    Data.$inject = ['$scope', '$rootScope', '$location', 'config'];
    function Data($scope, $rootScope, $location, config) {
        


    }

})();