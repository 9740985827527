(function(){
    'use strict';

    angular
        .module('ead.video')
        .controller('videoService', VideoService);

    VideoService.$inject = ['$http', 'config'];
    function VideoService($http, config) {
        var baseUrl = config.baseUrl,
            start_time = new Date(),
            request_time = 0;
    }

})();