(function() {
    'use strict';

    angular.module('ead.core', [
        'ngAnimate',
        'ngRoute',
        'webStorageModule'
    ]);

})();
