(function(){
    'use strict';

    angular
        .module('ead.uc')
        .controller('UcCtrl', Uc);

    Uc.$inject = ['$scope', '$rootScope', '$location', 'config'];
    function Uc($scope, $rootScope, $location, config) {


    }

})();