(function() {
    'use strict';

    angular.module('ead.core').value('config', {
        // BaseUrl da pagina
        baseUrl: $('meta[name="base_url"]').attr('content'),
        // Imagem padrão
        photoStandard: '/1.png',
        // Token de form do laravel
        token: $('meta[name="csrf-token"]').attr('content')
    });

})();
