(function(){
    'use strict';

    angular
        .module('ead.uc')
        .service('ucService', UcService);

    UcService.$inject = ['$http', 'config'];
    function UcService($http, config) {
        var baseUrl = config.baseUrl,
            start_time = new Date(),
            request_time = 0;


        var service = {
            getUcModules : getUcModules
        }

        function getUcModules (ucId) {
            return {
                "id": 2,
                "name": "Cras justo odio, dapibus ac facilisis in, egestas eget quam.",
                "image": "path/to/image.jpg",
                "description": "Duis mollis, est non commodo luctus, nisi erat porttitor ligula, eget lacinia odio sem nec elit. Aenean lacinia bibendum nulla sed consectetur. Morbi leo risus, porta ac consectetur ac, vestibulum at eros. Etiam porta sem malesuada magna mollis euismod. Integer posuere erat a ante venenatis dapibus posuere velit aliquet. Curabitur blandit tempus porttitor. Maecenas sed diam eget risus varius blandit sit amet non magna.",
                "duration": "2 horas",
                "responsible": "WeJam",
                "publish": "01/01/2015",
                "expiry": null,
                "categories": [
                    {
                        "id": 1,
                        "name": "Cras justo odio, dapibus ac facilisis in, egestas eget quam."
                    }
                ],
                "tracks": [
                    {
                        "id": 1,
                        "name": "Colaboradores"
                    }
                ],
                "pretest": {
                    "completed": true,
                    "required": false,
                    "data": {
                        "name": "Cras justo odio, dapibus ac facilisis in, egestas eget quam.",
                        "questions": 5,
                        "duration": "00:15:00"
                    }
                },
                "courses": [
                    {
                        "id": 1,
                        "name": "Cras justo odio, dapibus ac facilisis in, egestas eget quam.",
                        "image": "path/to/image.jpg",
                        "type": 1,
                        "description": "Cras justo odio, dapibus ac facilisis in, egestas eget quam.",
                        "completed": true,
                        "required": true,
                        "percent": 0
                    }
                ],
                "has_practice": true,
                "practice": {
                    "completed": false,
                    "required": false,
                },
                "finaltest": {
                    "completed": false,
                    "required": true,
                    "data": {
                        "name": "Cras justo odio, dapibus ac facilisis in, egestas eget quam.",
                        "questions": 17,
                        "duration": "00:30:00"
                    }
                },
                "certificate": {
                    "reaction" : {
                        "completed": false,
                        "required": true,
                        "data": {
                            "name": "Cras justo odio, dapibus ac facilisis in, egestas eget quam.",
                        }
                    }
                },
            };
        }

        return service;
    }



})();