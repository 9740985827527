(function(){
    'use strict';

    angular
        .module('ead.home')
        .controller('homeService', HomeService);

    HomeService.$inject = ['$http', 'config'];
    function HomeService($http, config) {
        var baseUrl = config.baseUrl,
            start_time = new Date(),
            request_time = 0;
    }

})();