$(document).ready(function(){
    $(".tree-career-holder .tree-container .back-green .leaf-green").hover(function(){
        $(".tree-career-holder .tree-container .back-green .leaf-green").addClass('opct');
        $(this).removeClass('opct');
    }, function(){
        $(".tree-career-holder .tree-container .back-green .leaf-green").removeClass('opct');
    });

    $(".tree-career-holder .tree-container .back-red .leaf-red").hover(function(){
        $(".tree-career-holder .tree-container .back-red .leaf-red").addClass('opct');
        $(this).removeClass('opct');
    }, function(){
        $(".tree-career-holder .tree-container .back-red .leaf-red").removeClass('opct');
    });

    $(".tree-career-holder .tree-container .back-orange .leaf-orange").hover(function(){
        $(".tree-career-holder .tree-container .back-orange .leaf-orange").addClass('opct');
        $(this).removeClass('opct');
    }, function(){
        $(".tree-career-holder .tree-container .back-orange .leaf-orange").removeClass('opct');
    });

    $(".tree-career-holder .tree-container .back-light-green .leaf-light-green").hover(function(){
        $(".tree-career-holder .tree-container .back-light-green .leaf-light-green").addClass('opct');
        $(this).removeClass('opct');
    }, function(){
        $(".tree-career-holder .tree-container .back-light-green .leaf-light-green").removeClass('opct');
    });

    $(".tree-container .back").hover(function(){
        $(this).siblings().addClass('opct');
    }, function(){
        $(this).siblings().removeClass('opct');
    });
    
    // fix on bottom
    function fix_tree(){
        var full_height = $('.header-career-holder').height() + $('.tree-container').height() + $(".admin-actions").height();
        if($(window).height() > full_height){
            if($(window).width() >= 768){
                var section_tree_height = $(window).height() - $('.header-career-holder').outerHeight() - $(".admin-actions").outerHeight() - 20;
            }else{
                var section_tree_height = $(window).height() - $('.header-career-holder').outerHeight() - $(".admin-actions").outerHeight();
            }
            $(".tree-career-holder").css('height', section_tree_height);
        }else{
            $(".tree-career-holder").css('height', $(".tree-container").height());
        }
    }

    fix_tree();

    $(window).on('resize', function(){
        fix_tree();
    });
});