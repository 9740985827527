(function() {
    'use strict';

    angular
        .module('ead')
        .directive('practice', Practice);

    function Practice() {
        return {
            restrict: 'E',
            templateUrl: window.location.pathname + '/../angular-ead/pages/home/modal/practice.html',
            controller: Controller,
            controllerAs: 'vmPractice'
        };
    }

    Controller.$inject = ['$scope', '$element', 'config'];
    function Controller($scope, $element, config) {

    }
})();