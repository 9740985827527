(function(){
    'use strict';

    angular
        .module('ead.video')
        .controller('VideoCtrl', Video);

    Video.$inject = ['$scope', '$rootScope', '$location', 'config'];
    function Video($scope, $rootScope, $location, config) {
        


    }

})();