(function () {
    'use strict';

    angular.module('ead')
        .config(routeConfig);

    function routeConfig($routeProvider, $locationProvider) {
        $routeProvider
            .when('/uc', {
                templateUrl: window.location.pathname + '/../angular-ead/pages/uc/index.html',
                controller: 'UcCtrl',
                controllerAs: 'vm',
                resolve: {
                    title: function() {
                        return "Meus Cursos";
                    }
                }
            })
            .when('/uc-presential', {
                templateUrl: window.location.pathname + '/../angular-ead/pages/uc/index.html',
                controller: 'UcCtrl',
                controllerAs: 'vm',
                resolve: {
                    title: function() {
                        return "Meus Cursos";
                    }
                }
            })
            .when('/uc/:uc_id', {
                templateUrl: window.location.pathname + '/../angular-ead/pages/uc/single.html',
                controller: 'UcSingleCtrl',
                controllerAs: 'vm',
                resolve: {
                    title: function() {
                        return "Curso (Nome do Curso)";
                    }
                }
            })
        //.otherwise ({ redirectTo: '/' });
        //$locationProvider.html5Mode(true);
    }
})();