(function() {
    'use strict';

    angular
        .module('ead')
        .directive('dissertation', Dissertation);

    function Dissertation() {
        return {
            restrict: 'E',
            templateUrl: window.location.pathname + '/../angular-ead/pages/home/modal/dissertation.html',
            controller: Controller,
            controllerAs: 'vmDisseration'
        };
    }

    Controller.$inject = ['$scope', '$element', 'config'];
    function Controller($scope, $element, config) {

    }
})();