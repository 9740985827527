$(document).on('click', '.modal-agree-term .back', function() {
    var url = window.location.origin;

    window.location.href = url;
})

$(document).on('click', '.back',  function() {
    $(".main-modal-container-career-holder").removeClass('active');

    setTimeout( function() {
        $(".main-modal-container-career-holder").removeClass('animation');
    }, 100);
});

$(document).on('click', '.main-modal .icon',  function() {
    $(".main-modal-container-career-holder").removeClass('active');

    setTimeout( function() {
        $(".main-modal-container-career-holder").removeClass('animation');
    }, 100);
});

$(document).on('click', '.filter-area-results .result-item', function(e) {
    openModal($(this).data('modal-id'));

    e.preventDefault();
});

function openModal(id) {
    $.ajax({
        url: window.location.origin + '/career/details/' + id
    }).done(function(response) {
        $('#modalDetails').html(response);

        $(".main-modal-container-career-holder").addClass('active');

        setTimeout(function(){
            $(".main-modal-container-career-holder").addClass('animation');
        }, 100);
    });
}