(function () {
    'use strict';

    angular.module('ead')
        .config(routeConfig);

    function routeConfig($routeProvider, $locationProvider) {
        $routeProvider
            .when('/data', {
                templateUrl: window.location.pathname + '/../angular-ead/pages/data/index.html',
                controller: 'DataCtrl',
                controllerAs: 'vm',
                resolve: {
                    title: function () {
                        return 'Meus Dados';
                    }
                }
            })
        //.otherwise ({ redirectTo: '/' });
        //$locationProvider.html5Mode(true);
    }
})();