$(document).ready(function() {
    $('#search-career').on('input', function() {
        $('.loading-wrapper').css('opacity', 1);
    });

    $('#search-career').on('input', delay(function(e)
    {
        var search = this.value;

        $.ajax({
            url: window.location.origin + '/trilhas-de-carreira/search?name=' + search,
        })
        .done(function(response) {
            var htmlString = '';
            
            $('.loading-wrapper').css('opacity', 0);

            if(search.length > 0)
            {
                if(response.length > 0)
                {
                    response.forEach(function(item) {
                        htmlString += '<a href="#" class="node result-item" data-modal-id="' + item.id + '">' + item.name + '</a>';
                    });
                } else {
                    htmlString = '<p>Nenhum resultado encontrado.</p>';
                }
        
                $('.filter-area-results').html(htmlString);
        
                $('.filter-area-results').show();
            } else {
                $('.filter-area-results').hide();
            }
        });
    }, 700));

    $('#search-career').on('blur', function() {
        setTimeout( function() {
            $('.filter-area-results').hide();
        }, 120);
    });

    function delay(callback, ms) {
        var timer = 0;
        return function() {
            var context = this, args = arguments;

            clearTimeout(timer);

            timer = setTimeout(function () {
                callback.apply(context, args);
            }, ms || 0);
        };
    }
})